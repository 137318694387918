/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/react';
import Page from 'components/Page/Page';
import { Grid, Paper, CircularProgress } from '@material-ui/core';
import { ReactNode } from 'react';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeInCss = css`
  animation: ${fadeIn} 1s;
  animation-delay: 1s;
  animation-fill-mode: both;
`;

export default function LoadingScreen({ children }: { children?: ReactNode }) {
  return (
    <Page>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        css={css`
          flex-grow: 1;
        `}
      >
        {children ? (
          <Paper
            elevation={2}
            css={[
              css`
                text-align: center;
                padding: 2rem;
              `,
              fadeInCss,
            ]}
          >
            <p>{children}</p>
            <CircularProgress />
          </Paper>
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Page>
  );
}
