/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AsyncProgress from './AsyncProgress';

import { useState, useEffect } from 'react';
import deleteRun from 'store/deleteRun';

import IRunEntry from 'types/IRunEntry';

type IStatus = 'INIT' | 'PENDING' | 'ERROR' | 'SUCCESS';

interface IDeleteAsync {
  status: IStatus;
  run: IRunEntry;
}

interface Props {
  open: boolean;
  onClose: () => void;
  selectedRuns: IRunEntry[];
}

export default function DeleteConfirmModal(props: Props) {
  const { open, onClose, selectedRuns } = props;

  const [deleteAsyncList, setDeleteAsyncList] = useState<IDeleteAsync[]>([]);

  useEffect(() => {
    if (open) {
      setDeleteAsyncList(selectedRuns.map((run) => ({ status: 'INIT', run })));
    }
    // Hack: Only change when opening, otherwise runs will be removed as soon
    // as they are deleted. Keep showing them.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function deleteRuns() {
    deleteAsyncList
      .filter((async) => ['INIT', 'ERROR'].includes(async.status))
      .forEach((async) => {
        function setStatus(status: IStatus) {
          setDeleteAsyncList((list) =>
            list.map((some) =>
              some.run.id !== async.run.id ? some : { ...async, status }
            )
          );
        }

        setStatus('PENDING');
        deleteRun(async.run.id).then(
          () => setStatus('SUCCESS'),
          () => setStatus('ERROR')
        );
      });
  }

  const somePending = deleteAsyncList.some(
    (async) => async.status === 'PENDING'
  );
  const someFailed = deleteAsyncList.some((async) => async.status === 'ERROR');
  const allDone = deleteAsyncList.every((async) => async.status === 'SUCCESS');

  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        elevation={2}
        css={css`
          padding: 48px;

          position: fixed;
          top: 20%;
          left: 50%;
          transform: translateX(-50%);
          width: 400px;
          max-width: 100%;
        `}
      >
        <Typography component="h1" variant="h6">
          Delete
        </Typography>
        <br />
        <Typography variant="body1">
          Are you sure you want to delete the {selectedRuns.length} selected{' '}
          {selectedRuns.length === 1 ? 'workflow' : 'workflows'}?
        </Typography>

        <br />
        <ul
          css={css`
            margin: 0;
            padding: 0;

            list-style: none;

            li {
              margin-top: 8px;
            }

            max-height: 300px;
            overflow-y: auto;
          `}
        >
          {deleteAsyncList.map((deleteAsync) => (
            <li key={deleteAsync.run.id}>
              <AsyncProgress status={deleteAsync.status}>
                {deleteAsync.run.name}
              </AsyncProgress>
            </li>
          ))}
        </ul>

        <br />
        <div
          css={css`
            display: flex;
            justify-content: flex-end;

            button ~ button {
              margin-left: 16px;
            }
          `}
        >
          <Button onClick={onClose} disabled={allDone}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={somePending}
            onClick={allDone ? onClose : deleteRuns}
          >
            {someFailed ? 'Try again' : allDone ? 'Close' : 'Delete'}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}
