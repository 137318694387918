import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { matchSorter } from 'match-sorter';

import ISearchOption from './ISearchOption';

type ISearchItem = Record<string, string | number>;

interface IProps {
  id: string;
  options: ISearchOption[];
  selectedOptions: ISearchOption[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<ISearchOption[]>>;
}

export default function Search(props: IProps) {
  const [searchString, setSearchString] = useState('');

  const [isOpen, setIsOpen] = useState(false);

  const sortedOptions = matchSorter(
    // Autocomplete throws if getOptionLabel returns void
    props.options.filter((option) => option.value && option.label),
    searchString,
    {
      keys: ['value'],
    }
  );

  return (
    <Autocomplete
      id={props.id}
      autoHighlight={true /* Press Enter -> select top suggestion */}
      filterSelectedOptions={true}
      multiple={true}
      openOnFocus={false}
      open={isOpen}
      onOpen={() => setIsOpen(searchString.length > 0)}
      onClose={() => setIsOpen(false)}
      options={[{ value: searchString, label: searchString }].concat(
        sortedOptions
      )}
      getOptionLabel={(option: ISearchOption) => option.label}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label="Search"
          variant="outlined"
          value={searchString}
          onChange={(event: any) => {
            const searchString = event.target.value;
            setSearchString(searchString);
            setIsOpen(searchString.length > 0);
          }}
        />
      )}
      value={props.selectedOptions}
      onChange={(event: any, newValue: ISearchOption[]) => {
        setSearchString('');
        props.setSelectedOptions(newValue);
      }}
    />
  );
}
