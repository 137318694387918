import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import ErrorScreen from 'components/ErrorScreen/ErrorScreen';

import useAutofetchedRunDetailsAsync from 'store/useAutofetchedRunDetailsAsync';
import useAutofetchedWorkflowDefinitionDetailsAsync from 'store/useAutofetchedWorkflowDefinitionDetailsAsync';
import clearState from './pageState/clearState';
import setSelectedWorkflowDefinition from './pageState/steps/setSelectedWorkflowDefinition';
import setInputsFormData from './pageState/steps/setInputsFormData';
import setCurrentStepIndex from './pageState/steps/setCurrentStepIndex';

import IRunDetails from 'types/IRunDetails';
import IWorkflowDefinitionDetails from 'types/IWorkflowDefinitionDetails';

export default function FetchRunDetails() {
  const { runId } = useParams() as {runId: string};

  const runDetailsAsync = useAutofetchedRunDetailsAsync(runId);

  switch (runDetailsAsync.status) {
    case 'INIT':
    case 'PENDING':
    case 'REFRESH':
      return <LoadingScreen />;
    case 'ERROR':
      return <ErrorScreen />;
    case 'SUCCESS':
      return <FetchDefinitionDetails runDetails={runDetailsAsync.data} />;
  }
}

function FetchDefinitionDetails({ runDetails }: { runDetails: IRunDetails }) {
  const definitionDetailsAsync = useAutofetchedWorkflowDefinitionDetailsAsync(
    runDetails.from_definition_id
  );

  switch (definitionDetailsAsync.status) {
    case 'INIT':
    case 'PENDING':
    case 'REFRESH':
      return <LoadingScreen />;
    case 'ERROR':
      return <ErrorScreen />;
    case 'SUCCESS':
      return (
        <SetStateAndRedirect
          runDetails={runDetails}
          definitionDetails={definitionDetailsAsync.data}
        />
      );
  }
}

function SetStateAndRedirect({
  runDetails,
  definitionDetails,
}: {
  runDetails: IRunDetails;
  definitionDetails: IWorkflowDefinitionDetails;
}) {
  useEffect(() => {
    clearState();
    setSelectedWorkflowDefinition(definitionDetails);
    setInputsFormData(runDetails.inputs);
    setCurrentStepIndex(1);
  }, [runDetails, definitionDetails]);

  return <Redirect to={`/projects/${runDetails.project_id}/workflows/new`} />;
}
