import produce from 'immer';
import store from 'store';

export default function setRunName(runName: string) {
  store.setState(
    produce((state: any) => {
      state.startWorkflow.submit.runName = runName;
    })
  );
}
