import create from 'zustand';

import {
  IStartWorkflowState,
  initialStartWorkflowState,
} from 'pages/StartWorkflowRunPage/pageState/state';

import { IZipState, initialZipState } from 'shared/ZipProgresses/zipState';

// Types
import IAsync from 'types/IAsync';
import IAuthState from 'types/IAuthState';
import IUser from 'types/IUser';
import IAccount from 'types/IAccount';
import IProject from 'types/IProject';
import IRunEntry from 'types/IRunEntry';
import IRunDetails from 'types/IRunDetails';
import IRunFile from 'types/IRunFile';
import ITaskDetails from 'types/ITaskDetails';
import IWorkflowDefinition from 'types/IWorkflowDefinition';
import IWorkflowDefinitionDetails from 'types/IWorkflowDefinitionDetails';

type Id = string;

export interface State {
  authStateAsync: IAsync<IAuthState>;
  profileAsync: IAsync<IUser>;
  accountsAsync: IAsync<IAccount[]>;

  projectsAsync: IAsync<IProject[]>;

  projectStateMap: Record<Id, IProjectState>;

  startWorkflow: IStartWorkflowState;

  zipState: IZipState;
}

export interface IProjectState {
  usersAsync: IAsync<IUser[]>;
  runsAsync: IAsync<IRunEntry[]>;
  runDetailsAsyncMap: Record<Id, IAsync<IRunDetails>>;
  runFilesAsyncMap: Record<Id, IAsync<IRunFile[]>>;
  taskDetailsAsyncMap: Record<Id, IAsync<ITaskDetails>>;
  workflowDefinitionsAsync: IAsync<IWorkflowDefinition[]>;
  workflowDefinitionDetailsAsyncMap: Record<
    Id,
    IAsync<IWorkflowDefinitionDetails>
  >;
}

// Store
const useStore = create<State>(() => ({
  authStateAsync: { status: 'INIT' },
  profileAsync: { status: 'INIT' },
  accountsAsync: { status: 'INIT' },

  projectsAsync: { status: 'INIT' },

  projectStateMap: {},

  startWorkflow: initialStartWorkflowState,

  zipState: initialZipState,
}));

export { useStore };
export default useStore;

// @ts-ignore
window.store = useStore;
