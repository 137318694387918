/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import entailLogo from 'logos/entail.svg';

export default function Footer() {
  return (
    <footer
      css={css`
        margin-top: auto;

        min-height: 115px;
        padding: 8px 50px;
        padding-left: 90px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        background-color: var(--indigo);

        img {
          height: 30px;
          display: block;
        }
      `}
    >
      <a href="https://entail.no">
        <img alt="Entail" src={entailLogo} />
      </a>
    </footer>
  );
}
