/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { useState, useRef, ReactNode } from 'react';

const initialOpen = false;

interface IProps {
  summary: ReactNode;
  contentPlaceholder: ReactNode;
  renderContent: () => ReactNode;
}

export default function ExpandableDetails({
  summary,
  contentPlaceholder,
  renderContent,
}: IProps) {
  const [open, setOpen] = useState(initialOpen);

  const ref = useRef<any>(null);

  const toggle = () => {
    const nextOpen = !open;

    setOpen(nextOpen);

    if (ref.current) {
      ref.current.open = nextOpen;
    }
  };

  return (
    <Typography
      component="div"
      variant="body1"
      css={css`
        display: flex;
        align-items: flex-start;

        svg {
          font-size: 20px;
        }

        details {
          flex-grow: 1;
        }
        details summary::-webkit-details-marker {
          display: none;
        }
        details summary {
          padding: 8px;
          margin: 3px;
          padding-left: 4px;
          margin-left: -4px;

          letter-spacing: 0.5px;

          cursor: pointer;
        }
      `}
    >
      <IconButton
        onClick={toggle}
        aria-hidden={true /* This button is just visual extra */}
      >
        {open ? <RemoveIcon /> : <AddIcon />}
      </IconButton>
      <details
        ref={ref}
        open={initialOpen}
        onToggle={(event) => {
          if (event.nativeEvent.target && 'open' in event.nativeEvent.target) {
            // @ts-ignore
            setOpen(event.nativeEvent.target.open);
          }
        }}
      >
        <summary>{summary}</summary>
        {open ? renderContent() : contentPlaceholder}
      </details>
    </Typography>
  );
}
