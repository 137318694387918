/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useCallback, useRef } from 'react';
import cytoscape from 'cytoscape';

import IconButton from '@material-ui/core/IconButton';
import ImageAspectRatioIcon from '@material-ui/icons/ImageAspectRatio';

import { Dispatch, SetStateAction } from 'react';
import IRunDetails from 'types/IRunDetails';

// require('cytoscape-dagre') because there aren't any @types/ for it
cytoscape.use(require('cytoscape-dagre'));

const style = require('./TaskGraph.cytoscape-style').default;

export default function TaskGraph({
  runDetails,
  setSelectedTaskIds,
}: {
  runDetails: IRunDetails;
  setSelectedTaskIds: Dispatch<SetStateAction<string[]>>;
}) {
  const resetPanZoomRef = useRef(() => {});

  const onRef = useCallback(
    (div: any) => {
      if (!div) {
        return;
      }

      var cy = cytoscape({
        container: div,
        // @ts-ignore – classes is a valid property on node/edge
        elements: getGraphData(runDetails),
        style,
      });

      cy.layout({ name: 'dagre' }).run();

      /*
       * Selection: Like example, but use 'select' and 'unselect' instead of 'click':
       * https://js.cytoscape.org/#cy.collection
       * (Also, don't bother using cy.collection(), just array of id's.)
       */
      cy.nodes().on('select', (event) => {
        const id = event.target.id();
        setSelectedTaskIds((ids) => ids.concat(id));
      });
      cy.nodes().on('unselect', (event) => {
        const id = event.target.id();
        setSelectedTaskIds((ids) =>
          ids.filter((remaining: string) => remaining !== id)
        );
      });

      resetPanZoomRef.current = () => {
        cy.animate(
          { fit: { eles: cy.elements(), padding: 30 } },
          { duration: 0 }
        );
      };
    },
    [runDetails, setSelectedTaskIds]
  );

  return (
    <div
      css={css`
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        position: relative;
        button {
          position: absolute;
          top: 12px;
          left: 12px;
          z-index: 99999;
        }
      `}
    >
      <IconButton onClick={() => resetPanZoomRef.current()}>
        <ImageAspectRatioIcon />
      </IconButton>
      <div
        ref={onRef}
        css={css`
          flex-grow: 1;
          min-height: 448px;
        `}
      />
    </div>
  );
}

function getGraphData(runDetails: IRunDetails) {
  const nodes = runDetails.tasks.map((task) => ({
    data: {
      id: task.id,
      name: task.name,
      width: Math.max(5, 8.5 * String(task.name).length + 20),
    },
    classes: [(runDetails.task_states[task.id] || '').toLowerCase()],
  }));

  const edges = Object.entries(runDetails.task_links).flatMap(
    ([source, targets]) =>
      targets.map((target) => ({
        data: {
          source,
          target,
        },
      }))
  );

  return [...nodes, ...edges];
}
