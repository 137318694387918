import * as Sentry from '@sentry/browser';
import api from 'api';
import produce from 'immer';
import store from 'store';
import postAsync from './postAsync';

import getProjectId from 'utils/url/getProjectId';

export default function getUploadUrls() {
  const fileset = store.getState().startWorkflow.submit.filesetAsync.data;

  if (!fileset) {
    return Promise.reject();
  }

  const { fileMap } = store.getState().startWorkflow.steps.provideFiles;

  const tags = Object.entries(fileMap).reduce((map, [key, files]) => {
    if (files) {
      map[key] = files.map((file) => file.name);
    }
    return map;
  }, {} as Record<string, string[]>);

  return postAsync({
    post() {
      return api
        .post(`projects/${getProjectId()}/filesets/${fileset.id}/uploads`, {
          json: { tags },
        })
        .json()
        .catch((error) => {
          Sentry.captureException(error);
          throw error;
        });
    },
    getAsync() {
      return store.getState().startWorkflow.submit.uploadUrlsAsync;
    },
    setAsync(async) {
      store.setState(
        produce((state: any) => {
          state.startWorkflow.submit.uploadUrlsAsync = async;
        })
      );
    },
  });
}
