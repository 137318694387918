import { useStore } from 'store';

import IAsync from 'types/IAsync';
import IUser from 'types/IUser';

export default function useProjectsAsync(): IAsync<IUser> {
  const profileAsync = useStore((state: any) => state.profileAsync);

  return profileAsync;
}
