import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import AlertValid from './AlertValid';
import AlertIncomplete from './AlertIncomplete';
import Form from '@rjsf/material-ui';
import { IChangeEvent } from '@rjsf/core';
import validator from '@rjsf/validator-ajv6'

import { IFormData } from '../pageState/state';
import { JSONSchema7 } from 'json-schema';

interface IProps {
  schema: JSONSchema7;
  formData: IFormData;
  setFormData: (formData: Record<any, any>) => void;
}

export default function JsonForm(props: IProps) {
  const { schema, formData, setFormData } = props;

  const [liveValidate, setLiveValidate] = useState(false);

  return (
    <>
      <Form
        schema={schema}
        formData={formData}
        liveValidate={liveValidate
          /*
           * Must enable liveValidate to make error message go away when fixed.
           * This is a workaround to a bug:
           * https://github.com/rjsf-team/react-jsonschema-form/issues/2017
           */
        }
        onChange={(data: IChangeEvent<IFormData>) => {
          setFormData(data.formData);
        } }
        onSubmit={(data: IChangeEvent<IFormData>, event: React.FormEvent<IFormData>) => {
          setFormData(data.formData);
        } }
        onError={(errors: any) => {
          /*
           * Happens when user submits form, but there are errors.
           * Set onError prop only to prevent logging error to console.
           * State is already correctly set in onChange, so don't do anything here.
           */
        } }
        validator={validator}
      >
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => setLiveValidate(true)}
          disabled={liveValidate}
        >
          {liveValidate ? 'Showing any errors' : 'Show errors'}
        </Button>
      </Form>
    </>
  );
}
