/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import CaretDown from 'icons/CaretDown';
import Popover from 'components/Popover/Popover';
import * as Menu from 'components/MenuPanel/MenuPanel';

import { Auth } from 'aws-amplify';
import useAuthUserFullName from 'store/useAuthUserFullName';

export default function UserDropdown() {
  const fullName = useAuthUserFullName() || '';

  return (
    <Popover
      distancePx={8}
      content={({ close }) => (
        <Menu.Panel>
          <Menu.Section>
            <Menu.ItemLink to="/settings/profile">Settings</Menu.ItemLink>
            <Menu.Item onClick={() => Auth.signOut()}>Log out</Menu.Item>
          </Menu.Section>
        </Menu.Panel>
      )}
    >
      <button
        css={css`
          padding: 8px;

          display: flex;
          align-items: center;

          svg {
            margin-left: 8px;
          }

          background: none;
          border: none;
          border-radius: 3px;

          font-size: inherit;
          font-family: inherit;
          font-weight: 400;
          line-height: 1.5;

          color: #ffffffe3;
          fill: #ffffffe3;
          stroke: #ffffffe3;

          cursor: pointer;

          &:hover {
            background: #fff2;
          }

          .user-full-name {
            font-weight: 700;
          }
        `}
      >
        {fullName}
        <CaretDown />
      </button>
    </Popover>
  );
}
