/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import IRunEntry from 'types/IRunEntry';

export default function StartButton({
  selectedRuns,
}: {
  selectedRuns: IRunEntry[];
}) {
  const run = selectedRuns[0];

  return (
    <Tooltip
      title={
        selectedRuns.length !== 1
          ? 'Select exactly one workflow to start a new one'
          : !run.from_definition_id
          ? 'The selected workflow is not based on a workflow definition'
          : `Start new workflow based on "${run.name}"`
      }
      placement="bottom-start"
    >
      <span>
        <Button
          component={Link}
          to={
            !run
              ? ''
              : `/projects/${run.project_id}/workflows/new-from/${run.id}`
          }
          variant="contained"
          color="primary"
          disabled={selectedRuns.length !== 1 || !run.from_definition_id}
        >
          Start new
        </Button>
      </span>
    </Tooltip>
  );
}
