import { useEffect, useCallback } from 'react';
import { useStore } from 'store';

import fetchWorkflowDefinitionDetails from 'store/fetchWorkflowDefinitionDetails';
import getCurrentProjectState from 'store/getCurrentProjectState';

import IAsync from 'types/IAsync';
import IWorkflowDefinitionDetails from 'types/IWorkflowDefinitionDetails';

const initAsync = { status: 'INIT' };

export default function useAutofetchedWorkflowDefinitionDetailsAsync(
  workflowDefinitionId: string
): IAsync<IWorkflowDefinitionDetails> {
  const workflowDefinitionDetailsAsync = useStore(
    useCallback(
      (state: any) =>
        getCurrentProjectState(state).workflowDefinitionDetailsAsyncMap[
          workflowDefinitionId
        ],
      [workflowDefinitionId]
    )
  );

  useEffect(() => {
    fetchWorkflowDefinitionDetails(workflowDefinitionId);
  }, [workflowDefinitionId]);

  return workflowDefinitionDetailsAsync || initAsync;
}
