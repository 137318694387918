/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Fragment } from 'react';
import Page from 'components/Page/Page';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TokenAsync from './TokenAsync';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import { useState, useEffect } from 'react';
import useGenerateToken from './useGenerateToken';
import useAutofetchedAccountsAsync from 'store/useAutofetchedAccountsAsync';

import IUser from 'types/IUser';

export default function ProfileSettingsSceen({ profile }: { profile: IUser }) {
  const accountsAsync = useAutofetchedAccountsAsync();

  const [accountId, setAccountId] = useState<string>('');

  useEffect(() => {
    // Autoselect account if only one
    if (!accountsAsync.data) {
      return;
    }
    if (accountsAsync.data.length !== 1) {
      return;
    }
    setAccountId(accountsAsync.data[0].id);
  }, [accountsAsync.data]);

  const { tokenAsync, generateToken } = useGenerateToken();

  return (
    <Page>
      <div
        css={css`
          margin: 4rem;

          > section ~ section {
            margin-top: 4rem;
          }
        `}
      >
        <Paper
          component="section"
          elevation={2}
          css={css`
            padding: 2rem;

            section {
              margin-top: 1rem;
            }
          `}
        >
          <Typography component="h1" variant="h4">
            Profile
          </Typography>
          <section>
            <Typography component="h2" variant="h6">
              Given name
            </Typography>
            <Typography variant="body1">{profile.given_name}</Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              Last name
            </Typography>
            <Typography variant="body1">{profile.family_name}</Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              Username
            </Typography>
            <Typography variant="body1">{profile.username}</Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              Organization name(s)
            </Typography>
            {(() => {
              switch (accountsAsync.status) {
                case 'INIT':
                case 'PENDING':
                  return <Typography variant="body1">Loading…</Typography>;
                case 'ERROR':
                  return (
                    <Typography variant="body1">Failed to fetch</Typography>
                  );
                case 'SUCCESS':
                case 'REFRESH':
                  return (
                    <Fragment>
                      {accountsAsync.data.map((account) => (
                        <Typography key={account.id} variant="body1">
                          {account.organization_name}
                        </Typography>
                      ))}
                    </Fragment>
                  );
              }
            })()}
          </section>
          <section>
            <Typography component="h2" variant="h6">
              Email
            </Typography>
            <Typography variant="body1">{profile.email}</Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              Phone number
            </Typography>
            <Typography variant="body1">{profile.phone_number}</Typography>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              Id
            </Typography>
            <Typography variant="body1">{profile.id}</Typography>
          </section>
        </Paper>

        <Paper
          component="section"
          elevation={2}
          css={css`
            padding: 2rem;

            section {
              margin-top: 1.5rem;
            }
          `}
        >
          <Typography component="h1" variant="h4">
            pyTailor settings
          </Typography>

          <section>
            <Typography component="h2" variant="h6">
              API worker ID
            </Typography>
            <Typography variant="body1">
              This is the same as your user id.
            </Typography>
            <br />
            <Alert
              icon={<span />}
              severity="info"
              css={css`
                &.MuiAlert-root {
                  color: rgba(0, 0, 0, 0.87);
                  background: rgba(33, 33, 33, 0.08);
                }
              `}
            >
              {profile.id}
            </Alert>
          </section>
          <section>
            <Typography component="h2" variant="h6">
              API secret key
            </Typography>
            <Typography variant="body1">
              Here you can generate a new API secret key. The key will only be
              visible once, but you can always create a new one. However, you
              can only have one API secret key at a time – by generating a new API secret key
              the old one is deleted and can no longer be used.
            </Typography>
            <br />

            <TokenAsync tokenAsync={tokenAsync} />
            <br />

            <div
              css={css`
                display: flex;

                > * ~ button {
                  margin-left: 1rem;
                }
              `}
            >
              {accountsAsync.data && accountsAsync.data.length !== 1 && (
                <FormControl
                  variant="outlined"
                  css={css`
                    &.MuiFormControl-root {
                      min-width: 200px;
                    }
                  `}
                >
                  <InputLabel id="select-account-label">
                    Organization
                  </InputLabel>
                  <Select
                    labelId="select-account-label"
                    id="select-account"
                    label="Organization"
                    value={accountId}
                    onChange={(event: any) => setAccountId(event.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {accountsAsync.data &&
                      accountsAsync.data.map((account) => (
                        <MenuItem key={account.id} value={account.id}>
                          {account.organization_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}

              <Button
                variant="contained"
                color="primary"
                disabled={tokenAsync.status === 'PENDING' || !accountId}
                onClick={() => {
                  if (!accountId) {
                    return;
                  }
                  generateToken({
                    userId: profile.id,
                    accountId,
                  });
                }}
              >
                Generate API secret key
              </Button>
            </div>
          </section>
        </Paper>
      </div>
    </Page>
  );
}
