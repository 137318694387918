import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const appear = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Popover = styled.div<{ distancePx: number }>`
  position: absolute;

  &.six-o-clock {
    top: calc(100% + ${(p) => p.distancePx}px);
    left: 50%;
    transform: translateX(-50%);
  }
  &.twelve-o-clock {
    bottom: calc(100% + ${(p) => p.distancePx}px);
    left: 50%;
    transform: translateX(-50%);
  }
  &.three-o-clock {
    left: calc(100% + ${(p) => p.distancePx}px);
    top: 50%;
    transform: translateY(-50%);
  }
  &.four-o-clock {
    left: calc(100% + ${(p) => p.distancePx}px);
    top: 0;
  }
  &.five-o-clock {
    top: calc(100% + ${(p) => p.distancePx}px);
    left: 0;
  }
  &.nine-o-clock {
    right: calc(100% + ${(p) => p.distancePx}px);
    top: 50%;
    transform: translateY(-50%);
  }
  &.eleven-o-clock {
    bottom: calc(100% + ${(p) => p.distancePx}px);
    right: 0px;
  }

  z-index: 100;

  min-width: 100%;

  animation: ${appear} 200ms;
`;
