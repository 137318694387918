/*
 * Example config found at:
 * https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resourcehttps://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource
 */
export default {
  Auth: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_ARc9841kP', // same for all non-prod environments
    userPoolWebClientId: '1kkju55infakcnmotl298tvos3', // different for every environment

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    cookieStorage: {
      domain: 'test.tailor.wf',
      path: '/',
      expires: 365,
      secure: false,
    },

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',

    // Hosted UI configuration
    oauth: {
      domain: 'logintest.tailor.wf',
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: 'https://test.tailor.wf/',
      redirectSignOut: 'https://test.tailor.wf/',
      responseType: 'code',
    },
  },
};
