/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useState } from 'react';

import InputsForm from './InputsForm';
import InputsEditor from './InputsEditor';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

import withWorkflowDefinitionDetails from '../withWorkflowDefinitionDetails';

import IWorkflowDefinitionDetails from 'types/IWorkflowDefinitionDetails';

interface IProps {
  workflowDefinitionDetails: IWorkflowDefinitionDetails;
}

function ProvideInputs(props: IProps) {
  const [mode, setMode] = useState<'form' | 'editor'>('form');

  return (
    <div>
      <div
        css={css`
          display: flex;
          justify-content: center;

          margin-bottom: 32px;
        `}
      >
        <ToggleButtonGroup
          exclusive
          value={mode}
          onChange={(e, mode) =>
            // Don't change mode to null (we want exactly one selected at all times)
            mode && setMode(mode)
          }
          aria-label="Input mode"
          css={css`
            button {
              padding-left: 33px;
              padding-right: 33px;
            }
          `}
        >
          <ToggleButton value="form" aria-label="Via form">
            Form
          </ToggleButton>
          <ToggleButton value="editor" aria-label="Via json editor">
            Editor
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      {(() => {
        switch (mode) {
          case 'form':
            return <InputsForm {...props} />;
          case 'editor':
            return <InputsEditor />;
        }
      })()}
    </div>
  );
}

export default withWorkflowDefinitionDetails(ProvideInputs);
