/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import FileTable from './FileTable';

import useAutofetchedRunFilesAsync from 'store/useAutofetchedRunFilesAsync';

export default function Files(props: { runIds: string[] }) {
  return (
    <section
      css={css`
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        > * {
          flex-grow: 1;
        }
      `}
    >
      <Paper elevation={1} style={{ padding: 48 }}>
        <Typography component="h1" variant="h6">
          Files
        </Typography>
        <br />
        {(() => {
          switch (props.runIds.length) {
            case 0:
              return <div>Select workflow to list files.</div>;
            case 1:
              return <FilesAsync runId={props.runIds[0]} />;
            default:
              return (
                <div>
                  Multiple workflows selected. Select one to list files.
                </div>
              );
          }
        })()}
      </Paper>
    </section>
  );
}

function FilesAsync(props: { runId: string }) {
  const filesAsync = useAutofetchedRunFilesAsync(props.runId);

  switch (filesAsync.status) {
    case 'INIT':
    case 'PENDING':
      return (
        <div>
          <LinearProgress />
        </div>
      );
    case 'ERROR':
      return <div>Something went wrong</div>;
    case 'SUCCESS':
    case 'REFRESH':
      const files = filesAsync.data;
      if (files.length === 0) {
        return <div>No files</div>;
      }
      return <FileTable files={files} runId={props.runId} />;
  }
}
