import * as Sentry from '@sentry/browser';
import api from 'api';
import store from 'store';

import IAsync from 'types/IAsync';
import IAccount from 'types/IAccount';

function setAccountsAsync(accountsAsync: IAsync<IAccount[]>) {
  store.setState((state: any) => ({
    ...state,
    accountsAsync,
  }));
}

export default function fetchAccounts() {
  const async = store.getState().accountsAsync;

  switch (async.status) {
    case 'PENDING':
    case 'REFRESH':
      // Already fetching
      return;
    case 'INIT':
    case 'ERROR':
      setAccountsAsync({ status: 'PENDING' });
      break;
    case 'SUCCESS':
      // Only fetch this once
      break;
  }

  api
    .get(`accounts`)
    .json()
    .then(
      (data) => {
        setAccountsAsync({ status: 'SUCCESS', data: data as IAccount[] });
      },
      (error) => {
        Sentry.captureException(error);
        setAccountsAsync({ status: 'ERROR' });
      }
    );
}
