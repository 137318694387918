import React from 'react';
import JsonEditor from '../JsonSchemaFiller/JsonEditor';

import useInputsFormData from '../pageState/steps/useInputsFormData';
import setInputsFormData from '../pageState/steps/setInputsFormData';

export default function InputsEditor() {
  const formData = useInputsFormData();

  return (
    <JsonEditor
      formData={formData}
      setFormData={setInputsFormData}
    />
  );
}
