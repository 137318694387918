/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteConfirmModal from './DeleteConfirmModal';

import { useState } from 'react';
import useProfileAsync from 'store/useProfileAsync';

import IRunEntry from 'types/IRunEntry';

function useCanDeleteRuns(runs: IRunEntry[]) {
  // Default to Number.NaN, as it is not equal to anything, even itself
  const { data: currentUser = { id: Number.NaN } } = useProfileAsync();

  return runs.every((run) => run.user_id === currentUser.id);
}

export default function DeleteButton({
  selectedRuns,
}: {
  selectedRuns: IRunEntry[];
}) {
  const canDeleteSelected = useCanDeleteRuns(selectedRuns);

  const [showModal, setShowModal] = useState(false);

  return (
    <Fragment>
      <Tooltip
        title={
          selectedRuns.length === 0
            ? 'Select workflows to delete'
            : !canDeleteSelected
            ? 'You cannot delete workflows created by others'
            : `Delete selected workflows (${selectedRuns.length})`
        }
        placement="bottom-start"
      >
        <span>
          <Button
            variant="contained"
            color="primary"
            disabled={selectedRuns.length === 0 || !canDeleteSelected}
            onClick={() => setShowModal(true)}
          >
            Delete
          </Button>
        </span>
      </Tooltip>

      <DeleteConfirmModal
        open={showModal}
        onClose={() => setShowModal(false)}
        selectedRuns={selectedRuns}
      />
    </Fragment>
  );
}
