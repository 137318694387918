import { useEffect } from 'react';
import { State, useStore } from 'store';

import fetchRuns from 'store/fetchRuns';
import getCurrentProjectState from 'store/getCurrentProjectState';

import IAsync from 'types/IAsync';
import IRunEntry from 'types/IRunEntry';

export default function useAutofetchedRunDetailsAsync(): IAsync<IRunEntry[]> {
  const runsAsync = useStore(
    (state: State) => getCurrentProjectState(state).runsAsync
  );

  useEffect(() => {
    fetchRuns();
  }, []);

  return runsAsync;
}
