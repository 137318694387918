/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

import RunState from 'types/RunState';

export default function RunStateLabel({ state }: { state: RunState }) {
  return (
    <div
      css={css`
        color: #767676;

        &.ready {
          color: #2e92f2;
        }
        &.waiting {
          color: #1f62a2;
        }
        &.running {
          color: #f4b90b;
        }
        &.failed {
          color: #db0051;
        }
        &.completed {
          color: #24c75a;
        }
        &.reserved {
          color: #bb8bc1;
        }
        &.archived {
          color: #7f8287;
        }
        &.stopped {
          color: #b7bcc3;
        }
        &.paused {
          color: #ffcfca;
        }
      `}
      className={`${state.toLowerCase()}`}
    >
      {state}
    </div>
  );
}
