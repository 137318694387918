/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import useSteps, { IStep } from './useSteps';

import useCurrentStepIndex from '../pageState/steps/useCurrentStepIndex';
import setCurrentStepIndex from '../pageState/steps/setCurrentStepIndex';

import { Stepper, Step, StepButton, Box, Button } from '@material-ui/core';

export default function Steps() {
  const currentStepIndex = useCurrentStepIndex();
  const steps = useSteps();
  const currentStep = steps[currentStepIndex] as IStep;
  const nextStep = steps[currentStepIndex + 1] as IStep | void;

  return (
    <div
      css={css`
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      `}
    >
      <Stepper activeStep={currentStepIndex} alternativeLabel nonLinear={true}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepButton
              onClick={step.canGoTo ? () => setCurrentStepIndex(index) : void 0}
              completed={step.isCompleted}
              disabled={!step.canGoTo}
              className={currentStepIndex < index ? 'upcoming' : ''}
              css={css`
                &.upcoming .MuiStepIcon-root.MuiStepIcon-completed {
                  color: rgba(0, 0, 0, 0.38);
                }
              `}
            >
              {step.title}
            </StepButton>
          </Step>
        ))}
      </Stepper>

      <Box
        m={2}
        css={css`
          flex-grow: 1;
        `}
      >
        {(currentStep.Component && <currentStep.Component />) ||
          (currentStep.render && currentStep.render())}
      </Box>

      <div
        css={css`
          text-align: right;
        `}
      >
        <Button
          disabled={currentStepIndex === 0}
          onClick={() => setCurrentStepIndex(currentStepIndex - 1)}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!nextStep || !nextStep.canGoTo}
          onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
