import React from "react";

function TailorLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      version="1.1"
      viewBox="0 0 24.667 7.959"
    >
      <defs>
        <path d="M19.277 127.756H46.113V139.851H19.277z"></path>
      </defs>
      <g transform="translate(-22.805 -131.274)">
        <g
          style={{
            lineHeight: "1.25",
            whiteSpace: "pre",
            inlineSize: "165.275",
          }}
          fill="#fff"
          fillOpacity="1"
          stroke="none"
          strokeWidth="0.265"
          fontFamily="TeX Gyre Adventor"
          fontSize="10.583"
          fontStretch="normal"
          fontStyle="normal"
          fontVariant="normal"
          fontWeight="normal"
          letterSpacing="0"
          wordSpacing="0"
        >
          <path
            style={{}}
            d="M26.138 134.015v-.709h-1.301v-2.032h-.783v2.032h-1.25v.71h1.25v5.08h.783v-5.08z"
          ></path>
          <path
            style={{}}
            d="M32.567 139.095v-5.789h-.783v1.027c-.571-.805-1.28-1.164-2.286-1.164-1.735 0-3.048 1.312-3.048 3.048 0 1.725 1.323 3.016 3.101 3.016.984 0 1.62-.328 2.233-1.154v1.016zm-.783-2.92c0 1.312-.973 2.338-2.222 2.338-1.313 0-2.318-1.026-2.318-2.35a2.272 2.272 0 012.275-2.275c1.27 0 2.265.995 2.265 2.286z"
          ></path>
          <path
            style={{}}
            d="M34.69 139.095v-5.789h-.783v5.79zm0-6.424v-1.333h-.783v1.333z"
          ></path>
          <path style={{}} d="M36.799 139.095v-7.82h-.783v7.82z"></path>
          <path
            style={{}}
            d="M43.945 136.217c0-1.768-1.27-3.048-3.006-3.048-1.714 0-3.037 1.301-3.037 2.995 0 1.778 1.301 3.069 3.1 3.069 1.694 0 2.943-1.28 2.943-3.016zm-.794.01c0 1.323-.91 2.286-2.17 2.286-1.333 0-2.286-.973-2.286-2.328 0-1.312.963-2.297 2.244-2.297 1.27 0 2.212.995 2.212 2.34z"
          ></path>
          <path
            style={{}}
            d="M47.472 133.941v-.772c-.836.042-1.333.328-1.64.963v-.826h-.783v5.79h.783v-3.377c0-1.08.55-1.672 1.64-1.778z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default TailorLogo;
