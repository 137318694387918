import * as Sentry from '@sentry/browser';
import api from 'api';
import produce from 'immer';
import store from 'store';
import postAsync from './postAsync';

import getProjectId from 'utils/url/getProjectId';

export default function createFileset() {
  return postAsync({
    post() {
      return api
        .post(`projects/${getProjectId()}/filesets`)
        .json()
        .catch((error) => {
          Sentry.captureException(error);
          throw error;
        });
    },
    getAsync() {
      return store.getState().startWorkflow.submit.filesetAsync;
    },
    setAsync(async) {
      store.setState(
        produce((state: any) => {
          state.startWorkflow.submit.filesetAsync = async;
        })
      );
    },
  });
}
