import produce from 'immer';
import store from 'store';

import { IZipFileState } from './zipState';

export function addZipFileState(
  zipFilename: string,
  zipFileState: IZipFileState
) {
  store.setState(
    produce((state: any) => {
      state.zipState.map[zipFilename] = zipFileState;
    })
  );
}

export function setZipFileStatus(
  zipFilename: string,
  status: 'ERROR' | 'SUCCESS'
) {
  store.setState(
    produce((state: any) => {
      state.zipState.map[zipFilename].status = status;
    })
  );
}

export function incrementDownloadedCount(zipFilename: string) {
  store.setState(
    produce((state: any) => {
      state.zipState.map[zipFilename].nofFilesDownloaded++;
    })
  );
}
