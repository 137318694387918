import { State, useStore } from 'store';

import IAsync from 'types/IAsync';
import IProject from 'types/IProject';

export default function useProjectsAsync(): IAsync<IProject[]> {
  const projectsAsync = useStore((state: State) => state.projectsAsync);

  return projectsAsync;
}
