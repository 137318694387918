/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import Page from 'components/Page/Page';
import { Grid, Paper, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { useParams } from 'react-router';

export default function UnavailableProjectPage() {
  const { projectId } = useParams() as {projectId: string};

  return (
    <Page>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        css={css`
          flex-grow: 1;
        `}
      >
        <Paper
          elevation={2}
          css={css`
            text-align: center;
            padding: 2rem;
          `}
        >
          <Typography component="h1" variant="h3">
            Not available
          </Typography>
          <Alert
            severity="warning"
            css={css`
              margin-top: 32px;
            `}
          >
            You don't have access to project <em>{projectId}</em>.
          </Alert>
        </Paper>
      </Grid>
    </Page>
  );
}
