/**
 * Based on code example:
 * https://material-ui.com/components/tables/#sorting-amp-selecting
 */

import React, { useMemo, useRef, ReactNode } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { useOrder, orderBy, IOrderState, IOrderProperty } from 'utils/ordering';

import IColumnDefinition from '../Table/IColumnDefinition';

interface ISelection {
  isSelected: (id: string) => boolean;
  selectOnly: (id: string) => void;
  multi?: {
    someSelected: boolean;
    allSelected: boolean;
    toggle: (id: string) => void;
    selectAll: () => void;
    deselectAll: () => void;
  };
}

interface HasId {
  id: string;
}

interface EnhancedTableProps<IItem> {
  classes: ReturnType<typeof useStyles>;

  selection?: ISelection;
  columns: IColumnDefinition<IItem>[];
  order: void | IOrderState<IItem>;
  toggleOrderOf: (property: IOrderProperty<IItem>) => void;
}

function EnhancedTableHead<IItem extends HasId>(
  props: EnhancedTableProps<IItem>
) {
  const { classes, columns, order, toggleOrderOf, selection } = props;

  function renderMultiCheckbox() {
    if (!selection || !selection.multi) {
      return;
    }
    const {
      someSelected,
      allSelected,
      selectAll,
      deselectAll,
    } = selection.multi;

    return (
      <TableCell padding="checkbox">
        <Checkbox
          checked={someSelected}
          indeterminate={someSelected && !allSelected}
          onClick={someSelected ? deselectAll : selectAll}
        />
      </TableCell>
    );
  }

  return (
    <TableHead>
      <TableRow>
        {renderMultiCheckbox()}
        {columns.map((column, index) => {
          const showCheckbox = selection && selection.multi;
          const isAfterCheckbox = showCheckbox && index === 0;

          const { orderProperty } = column;

          if (orderProperty) {
            return (
              <TableCell
                // @ts-ignore – column.key is string
                key={column.key}
                align="left"
                padding={isAfterCheckbox ? 'none' : 'normal'}
                sortDirection={
                  order && order.key === column.key ? order.direction : false
                }
              >
                <TableSortLabel
                  active={order ? order.key === column.key : false}
                  direction={
                    (order && order.key === column.key && order.direction) ||
                    'asc'
                  }
                  onClick={() => toggleOrderOf(orderProperty)}
                >
                  {column.header}
                  {/* {order && order.key === column.key && (
                    <span className={classes.visuallyHidden}>
                      {order.direction === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  )} */}
                </TableSortLabel>
              </TableCell>
            );
          } else {
            return (
              <TableCell
                // @ts-ignore – column.key is string
                key={column.key}
                align="left"
                padding={isAfterCheckbox ? 'none' : 'normal'}
              >
                {column.header}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      border: '1px solid #c4c4c4',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

interface IProps<IItem> {
  columns: IColumnDefinition<IItem>[];
  items: IItem[];

  selection: ISelection;
  defaultPageSize: number;
}

export default function EnhancedTable<IItem extends HasId>(
  props: IProps<IItem>
) {
  const { columns, selection } = props;
  const [order, toggleOrderOf] = useOrder<IItem>();

  const items = useMemo(
    () => (!order ? props.items : props.items.slice(0).sort(orderBy(order))),
    [order, props.items]
  );

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.defaultPageSize);

  const rowHeightPx = 33;

  const scrollContainerRef = useRef<any>();
  function resetScroll() {
    const node = scrollContainerRef.current;
    if (node) {
      node.scrollTop = 0;
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    resetScroll();
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <TableContainer
        style={{
          maxHeight:
            440 -
            (440 % rowHeightPx) +
            5 /* + 5 because header row is 5px taller */,
        }}
        ref={scrollContainerRef}
      >
        <Table
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
          stickyHeader={true}
        >
          <EnhancedTableHead<IItem>
            classes={classes}
            columns={columns}
            order={order}
            toggleOrderOf={toggleOrderOf}
            selection={selection}
          />
          <TableBody>
            {items
              .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((item, index) => {
                const isItemSelected = selection
                  ? selection.isSelected(item.id)
                  : false;
                const labelId = `enhanced-table-checkbox-${index}`;

                const multiSelection = selection && selection.multi;

                return (
                  <TableRow
                    key={item.id}
                    hover
                    onClick={
                      selection ? () => selection.selectOnly(item.id) : void 0
                    }
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                  >
                    {multiSelection && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={(event) => {
                            event.stopPropagation();
                            multiSelection.toggle(item.id);
                          }}
                        />
                      </TableCell>
                    )}
                    {columns.slice(0, 1).map((column) => (
                      <TableCell
                        key="title-cell"
                        component="th"
                        id={labelId}
                        scope="row"
                        padding={multiSelection ? 'none' : 'normal'}
                      >
                        {renderCell(item, column)}
                      </TableCell>
                    ))}
                    {columns.slice(1).map((column) => (
                      // @ts-ignore – column.key is string
                      <TableCell key={column.key} align="left">
                        {renderCell(item, column)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: rowHeightPx * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

function renderCell<IItem>(
  item: IItem,
  column: IColumnDefinition<IItem>
): ReactNode {
  if (typeof column.render === 'function') {
    return column.render(item);
  } else {
    return String(item[column.key]);
  }
}
