import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Link, NavLink } from 'react-router-dom';

const item = css`
  display: flex;
  align-items: center;
  padding: 4px 24px;
  white-space: nowrap;

  cursor: pointer;

  color: var(--color);
  fill: var(--color);

  &:hover,
  &.active {
    background: var(--hover-background);
    color: var(--hover-color);
    fill: var(--hover-color);
  }
  }
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0.00938em;

  > svg ~ span {
    /* Add space between icon and description. */
    margin-left: 8px;
  }
  > span ~ svg {
    /* Align icon right if it comes after a description. */
    margin-left: auto;
  }

  span {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Item = styled.button`
  width: 100%;
  background: none;
  font-size: 1rem;
  border: none;
  ${item};
`;

export const ItemLink = styled(Link)`
  text-decoration: none;
  ${item};
`;

export const ItemNavLink = styled(NavLink)`
  text-decoration: none;
  ${item};
`;

export const SectionLabel = styled.div`
  margin-bottom: 6px;

  font-size: 11px;
  color: #6c757d;

  text-align: center;
`;

export const Section = styled.div`
  padding: 8px 0;

  &:not(:first-of-type) {
    border-top: 1px solid var(--section-border-color);
  }
`;

export const Panel = styled.div`
  box-sizing: border-box;

  min-width: 160px;

  background: #fff;
  border: 1px solid #00000026;
  border-radius: 3px;

  --section-border-color: #e9ecef;

  --color: #212529;

  --hover-background: #5050501a;
  --hover-color: #16181b;
`;
