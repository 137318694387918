/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import Page from 'components/Page/Page';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export default function ErrorScreen() {
  return (
    <Page>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        css={css`
          flex-grow: 1;
        `}
      >
        <Paper
          elevation={2}
          css={css`
            text-align: center;
            padding: 2rem;
          `}
        >
          <Typography component="h1" variant="h3">
            Oops!
          </Typography>
          <Alert
            severity="warning"
            css={css`
              margin-top: 32px;
            `}
          >
            Something went wrong. Please reload the page.
          </Alert>
          <div
            css={css`
              margin-top: 48px;
            `}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.reload()}
            >
              Reload page
            </Button>
          </div>
        </Paper>
      </Grid>
    </Page>
  );
}
